
















































// @ts-ignore
import { VaultItem, VaultPostItem } from 'vaultifier';
import Vue, { PropType } from 'vue'
import RawJson from './RawJson.vue';
import CustomButton from './Button.vue';
import Spinner from './Spinner.vue';

interface Data {
  isEditable: boolean;
  editableData: string;
}

export default Vue.extend({
  components: {
    RawJson,
    CustomButton,
    Spinner,
  },
  props: {
    item: {
      type: Object as PropType<VaultItem>,
    },
    isSaving: {
      type: Boolean as PropType<boolean>,
    }
  },
  data: (): Data => ({
    isEditable: false,
    editableData: '',
  }),
  created() {
    this.resetEditableData();
  },
  methods: {
    resetEditableData() {
      this.editableData = JSON.stringify(this.item.data, undefined, 2);
    },
    save() {
      console.log('save')
      const postItem: VaultPostItem = {
        ...this.item,
      };

      this.$emit('save', postItem);
    },
  },
  watch: {
    editableData(value: string) {
      try {
        this.item.data = JSON.parse(value);
      } catch { /* */ }
    },
    item() {
      this.resetEditableData();
    },
  },
  computed: {
    meta(): any {
      const copy = { ...this.item };
      delete copy.data;
      // @ts-ignore
      delete copy.raw;

      return copy;
    },
    isEncrypted(): boolean {
      return this.item.isEncrypted;
    }
  }
});
