import { render, staticRenderFns } from "./FormView.vue?vue&type=template&id=5972b6fe&scoped=true&"
import script from "./FormView.vue?vue&type=script&lang=ts&"
export * from "./FormView.vue?vue&type=script&lang=ts&"
import style0 from "./FormView.vue?vue&type=style&index=0&id=5972b6fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5972b6fe",
  null
  
)

export default component.exports